import { variables} from 'config';
import getTheme from 'config/themes/base';

const theme = getTheme();

export default {
  root: {
    minHeight: `calc(100vh - ${variables.headerHeight} - ${variables.footerHeight} - 40px)`,
    width: '100%',
    borderRadius: 'unset',
    backgroundColor: theme.palette.primary.mainBackground,
    color: theme.palette.primary.main,
    flexGrow: 1,
    display: 'block',
    textAlign: 'start',
    padding: '24px 36px',
    fontFamily: 'Montserrat, sans-serif',
    fontStyle: 'normal',
    overflow: 'hidden',

    '@media (max-width: 980px)': {
      padding: '24px 24px'
    },
  },
  container: {
    marginTop: 0,
  },
  block: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
    marginTop: '12px'
  },
  topLevelRow: {
    height: '264px',
    // width: '308px'
  },
  secondLevelRow: {
    height: '500px'
  },
  thirdLevelRow: {
    height: '251px',
    // width: '474px'
  },
  fourthLevelRow: {
    minHeight: '435px',
    maxHeight: '870px',
  },
  noDataText: {
    textAlign: 'center',
    marginTop: '50px',
  },
  widgetTitle: {
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  widgetSubTitle: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400,
    display: 'flex',
    opacity: '0.8',
    marginTop: '8px',
    textTransform: 'none'
  },
  dateFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    flexShrink: 1
  },
  dateFilterStyle: {
    padding: '8px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.mainBackground,
  },
  mobileStyle: {
    backgroundColor: '#fff',
  },
  noAccess: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    fontWeight: '500',
    fontSize: '25px',
    textAlign: 'center',
  }
};
