import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { Box, useMediaQuery, Typography } from '@mui/material';

import Loading from 'components/common/loading';
import PageHeader from 'components/common/pageHeader';
import domainData from 'store/domainStore';
import { getMessage } from 'helpers/helper';
import M from 'messages';
import settingsStore from 'store/settingsStore';
import ComingSoon from 'components/common/comingSoon';


import classes from './styles';

const Dashboard = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const downSM = useMediaQuery('(max-width:980px)');
  const [loading, setLoading] = useState(true);
  const [dashboardUrl, setDashboardUrl] = useState(false);
  const checkPermissions = async () => {
    try {
      const isUserSuperAdmin = domainData.userIsSuperAdmin();
      if (isUserSuperAdmin || await domainData.isUserHaveDashboardAccess()) {
        let url = "https://lookerstudio.google.com/embed/u/0/reporting/372de268-3776-45fa-a96e-688318586ea7/page/KH3XD";
        if (!isUserSuperAdmin) {
          const params = {
            "ds0.partner": domainData.getCurrentSpData().slug
          };
          const paramsAsString = JSON.stringify(params);
          const encodedParams = encodeURIComponent(paramsAsString)
          url += `?params=${encodedParams}`;
        }
        setDashboardUrl(url);
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getMessage(error?.response?.data, 'error'), { variant: 'error' });
      setLoading(false);
    }
  }
  useEffect(() => {
    checkPermissions();
    settingsStore.getDashboard();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!settingsStore.getShowDashboard()) {
    return <ComingSoon />;
  }

  return loading
    ? <Loading />
    : <Box sx={{...classes.root, ...(downSM && classes.mobileStyle)}}>
      <PageHeader
        generalPageTitle={M.get('dashboard.title')}
        onlyGeneralPageTitle
      />
      {dashboardUrl
        ? <iframe title="Report" src={dashboardUrl} style={{ border: '0', width: '100%', height: '100%'}} allowFullScreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"/>
        : <Typography sx={classes.noAccess}>{M.get('dashboard.noAccess')}</Typography> 
      }
    </Box >
})

export default Dashboard;
