import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import kiosksMenuStore from 'store/kiosksMenusStore';
import M from 'messages'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import classes from '../styles';

const DraggableTableRow = ({ item, index }) => (
  <Draggable key={String(item.order)} draggableId={String(item.order)} index={index}>
    {(provided, snapshot) => {
      const style = {
        ...provided.draggableProps.style,
        background: snapshot.isDragging ? '#f0f0f0' : !item.active ? 'rgb(227 227 227)' : 'white',
        boxSizing: 'border-box',
        display: snapshot.isDragging ? 'flex' : 'table-row',
        tableLayout: 'fixed',
        cursor: 'grab',
      };
      return (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={style}
        >
          <TableCell>     
            <DragIndicatorIcon sx={classes.dragIcon}/>
          </TableCell>
          <TableCell style={{ width: '19%' }}>{item.name}</TableCell>
          <TableCell style={{ width: '19%' }}>{item.producer}</TableCell>
          <TableCell style={{ width: '15%' }}>{item.wineCountry}</TableCell>
          <TableCell style={{ width: '7%' }}>{item.year}</TableCell>
          <TableCell style={{ width: '7%' }}>{item.color}</TableCell>
          <TableCell style={{ width: '7%' }}>{item.style}</TableCell>
          <TableCell style={{ width: '7%' }}>{item.sweetness}</TableCell>
          <TableCell style={{ width: '7%' }}>{item.isSuperVino ? 'Yes' : 'No'}</TableCell>
          <TableCell style={{ width: '12%' }}>{item.tableOrWineNumber || 'N/A'}</TableCell>
        </TableRow>
      );
    }}
  </Draggable>
);

DraggableTableRow.propTypes = {
  item: PropTypes.shape({
    order: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.string,
    sweetness: PropTypes.string,
    isSuperVino: PropTypes.bool,
    active: PropTypes.bool,
    producer: PropTypes.string,
    wineCountry: PropTypes.string,
    tableNumber: PropTypes.string,
    tableOrWineNumber: PropTypes.number,
  }),
  index: PropTypes.number,
};

const DraggableTable = ({ selectedItems }) => {
  const handleDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const newItems = Array.from(selectedItems);
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);
    kiosksMenuStore.setSortedSelectedMenuItems(newItems);
  };

  return (
    !!selectedItems.length && (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <TableContainer component={Paper} ref={provided.innerRef} {...provided.droppableProps}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ width: '19%' }}>{M.get('table.menuMenuItems.name')}</TableCell>
                    <TableCell style={{ width: '19%' }}>{M.get('table.menuMenuItems.producer')}</TableCell>
                    <TableCell style={{ width: '15%' }}>{M.get('table.menuMenuItems.wineCountry')}</TableCell>
                    <TableCell style={{ width: '7%' }}>{M.get('table.menuMenuItems.year')}</TableCell>
                    <TableCell style={{ width: '7%' }}>{M.get('table.menuMenuItems.color')}</TableCell>
                    <TableCell style={{ width: '7%' }}>{M.get('table.menuMenuItems.style')}</TableCell>
                    <TableCell style={{ width: '7%' }}>{M.get('table.menuMenuItems.sweetness')}</TableCell>
                    <TableCell style={{ width: '7%' }}>{M.get('table.menuMenuItems.isSuperVino')}</TableCell>
                    <TableCell style={{ width: '12%' }}>{M.get('table.menuMenuItems.tableNumber')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedItems.map((item, index) => (
                    <DraggableTableRow key={item.order} item={item} index={index} />
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    )
  );
};

DraggableTable.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
};

export default DraggableTable;